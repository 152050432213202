@import 'tokens.scss';

.UploadVideoModal {
  padding: 1rem;
  display: grid;
  gap: 0.5rem;
  &__footer {
    display: flex;
    justify-content: flex-end;
  }
  &__cancel-button {
    margin-right: 1rem;
  }
}
