@import 'tokens.scss';

.LocationLabel {
  border-radius: 1.25rem;
  &__icon-circle {
    $size: 2rem;
    height: $size;
    width: $size;
    color: $text-on-grey-100;
    border-radius: 100rem;
    display: grid;
    place-items: center;
    font-size: $text-size-m;
    &--grey-bg {
      background-color: $color-grey-100;
    }
  }
  &__label {
    color: $text-on-white;
    font-size: $text-size-s;
    line-height: 1.2;
  }
  &__delete-button {
    height: 100%;
    width: 100%;
    border-radius: 100rem;
    display: grid;
    place-items: center;
    outline: none;
    border: none;
    background-color: transparent;
    color: $text-on-white--subdued;
    font-size: $text-size-l;
    cursor: pointer;
    &:hover {
      background-color: $color-danger-200;
      color: $text-on-danger-200;
    }
    &:focus-visible {
      box-shadow: $focus-ring;
      background-color: $color-danger-200;
      color: $text-on-danger-200;
    }
  }
  &:hover {
    background-color: $color-grey-050;
    .LocationLabel {
      &__delete-button {
        opacity: 1;
      }
      &__icon-circle {
        &--grey-bg {
          background-color: $color-grey-200;
        }
      }
    }
  }
}
