@import 'tokens';

.SelectBundle {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__star {
    color: $color-tertiary-400;
  }
  &__bundle-list {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 560px) {
      grid-template-columns: 1fr;
    }
  }
}
