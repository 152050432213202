@import 'tokens';
@import 'mixins';

.CreditBundleCard {
  &__title {
    text-align: center;
    text-transform: uppercase;
    padding: 0.5rem 0;
  }
  &__button {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: $color-grey-100;
    color: $text-on-grey-100;
    padding: 0 1rem 1rem 1rem;
    width: 15rem;
    height: 11rem;
    box-shadow: $box-shadow-200;
    border: $pixels-2 solid transparent;
    position: relative;

    @include focus-ring;
  }
  &__star {
    color: $color-tertiary-400;
    margin-left: -1.2rem;
    &:nth-child(even) {
      color: $color-tertiary-300;
      padding-bottom: 0.15rem;
      width: 2rem;
    }
  }
  &__star-container {
    width: 10rem;
    max-height: 5rem;
    margin: 2rem 0 1rem 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }
}
