@import 'src/tokens';

.TableSelectionWrapper {
  &__table-wrapper {
    padding: 1.5rem 4rem;
    max-width: 110rem;
    margin: 0 auto;
    position: relative;
    &--selection {
      &::before {
        content: '';
        position: absolute;
        width: 2rem;
        height: 2rem;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid $color-tertiary-300;
        left: 4.5rem;
        top: 0;
      }
    }
  }
  &__selection-header {
    background: $color-tertiary-300;
    height: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    &__actions {
      display: flex;
    }
  }
}
