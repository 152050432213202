@import 'tokens.scss';

.CampaignSetup {
  height: 100%;
  &__next-button {
    margin-left: 2rem;
  }
  &__name {
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin: 0 0 1rem 0;
  }
  &__input-label {
    margin: 1rem 0 0.25rem 0;
  }
  &__name-input {
    max-width: 40rem;
  }
  &__audiences {
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }
  &__audience-list {
    margin-top: 1rem;
  }
  &__empty {
    padding: 1rem;
    background-color: $color-grey-100;
    display: grid;
    gap: 0.75rem;
    justify-items: center;
  }
}
