@import 'src/tokens';
@import 'src/mixins';

.BuzzScoreFilters {
  &__filters {
    display: flex;
    justify-content: space-between;
  }
  &__labels {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
  }
  &__filter {
    margin-top: 0.5rem;
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
  &__button {
    @include focus-ring;
    outline: none;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 100rem;
    cursor: pointer;
    border-width: $pixels-4;
    border-style: solid;
    text-decoration: none;
    background-color: $color-white;
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
  &__check {
    opacity: 0;
  }
}

.BuzzScoreFilters__filter--is-active {
  .BuzzScoreFilters__check {
    opacity: 1;
  }
}
