@import 'tokens';
@import 'mixins';

.ImpressionSelector {
  background: $color-white;
  padding: 1rem;
  margin: 1rem 0;
  &__selection {
    max-width: 15rem;
    margin: 1rem;
  }
}
