@import 'tokens';
@import 'mixins';

.SavedAudiences {
  &__select-action {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 1rem;

    @include focus-ring;
  }
  &__info {
    margin-left: 0.5rem;
    color: $text-on-white--subdued;
  }
}
