@import 'tokens.scss';

.SummaryCart {
  border: $border-width-default solid $color-grey-100;
  border-radius: $border-radius-m;
  box-shadow: $box-shadow-400;
  height: min-content;
  &__header-item {
    background: $color-grey-100;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
  }
  &__body-item {
    padding: 1rem;
    background: $color-grey-050;
  }
}
