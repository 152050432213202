@import 'src/tokens';

.CampaignStatusBadge {
  display: flex;
  align-items: center;
  background: white;
  border-radius: $border-radius-l;
  padding: 0.25rem 0.5rem;
  min-width: 12ch;
  font-weight: $text-weight-bold;
  color: $color-grey-600;
  border: 2px solid black;
  &__text-container {
    flex-grow: 1;
    text-align: center;
    font-size: $text-size-s;
  }
  &__indicator {
    background: $color-grey-050;
    height: 0.75rem;
    width: 0.75rem;
    border-radius: 50%;
    margin-right: 0.5rem;
  }
}
