.Login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  height: 100%;
  width: 25rem;
  &__logo {
    margin-bottom: 1rem;
  }
  &__form {
    width: 100%;
  }
  &__options {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
