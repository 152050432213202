@import 'tokens';

.Loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 10rem;
  width: 100%;
  margin-top: 7%;
  &__dot {
    display: inline-block;
    background-color: $color-primary-400;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    margin: 0 0.5rem;
    transform: translate3d(0);
    animation: bounce 0.6s infinite alternate;
  }
  &__dot:nth-child(2) {
    background-color: $color-primary-300;
    animation-delay: 0.2s;
  }
  &__dot:nth-child(3) {
    background-color: $color-primary-200;
    animation-delay: 0.4s;
  }
}

@keyframes bounce {
  to {
    width: 1rem;
    height: 1rem;
    transform: translate3d(0, -16px, 0);
  }
}
