.ProgressBar {
  width: 100%;
  &__steps {
    display: flex;
    align-items: center;
  }
  &__line {
    height: 0.5rem;
    background-color: #2b1f09;
    width: 1rem;
    margin: 0 -0.25rem;
    z-index: 1;
  }
  &__step {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100%;
    background-color: #2b1f09;
    z-index: 2;
  }
}

.ProgressBar__step--is-filled {
  background-color: #44c2e6;
}

.ProgressBar__line--is-filled {
  background-color: #44c2e6;
}
