@import 'tokens.scss';

.LensSidebar {
  flex: 0 0 400px;
  display: flex;
  flex-direction: column;
  box-shadow: $box-shadow-500-dark;
  background: $color-white;
  z-index: 2;
}
