@import 'tokens';

.MyListings {
  &__status-badge {
    padding: 0.25rem 0.5rem;
    border-radius: $border-radius-m;
    background: $color-grey-100;
    color: $text-on-grey-100;
    font-weight: bold;
  }
  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    color: $color-grey-200;
    padding: 6rem;
  }
}
