@import 'src/tokens';

.BillingSection {
  &__cards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 2rem;
  }
  &__select {
    min-width: 20rem;
  }
  &__transactions {
    background-color: $color-grey-050;
    color: $color-grey-300;
    padding: 2rem 0 1rem 0;
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__buttons {
    display: flex;
  }
  &__add-card {
    padding: 1rem;
    min-width: 30rem;
  }
}
