@import 'tokens.scss';

.PageHeader {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: $color-white;
  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    & > * {
      margin: 0 0.5rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
