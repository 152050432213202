@import 'src/tokens';

.SubscriptionSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__plans {
    display: flex;
    padding: 0.75rem;
  }
  &__arrow {
    color: $color-grey-200;
    display: flex;
    align-items: center;
    padding: 3rem 3rem 0 3rem;
  }
  &__button {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
