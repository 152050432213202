@import 'tokens';

.ListingPhotosMap {
  flex-grow: 1;
  &__photos {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  }
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__photo-thumbnail {
    height: auto;
    max-width: 100%;
    background: none;
    border: none;
    padding: 0;
    display: unset;
    margin: 0.5rem;
  }
}
