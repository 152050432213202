@import 'tokens.scss';
@import 'src/mixins';

.CreativeTypeBadge {
  @include focus-ring;
  border: none;
  cursor: pointer;
  padding: 3rem 0;
  background-color: $color-grey-100;
  &__icon {
    color: $text-on-grey-100;
    margin-bottom: 0.5rem;
  }
}
