@import 'src/tokens';

.ProgressIndicator {
  width: 100%;
  &__meter {
    background: $color-grey-400;
    height: 1rem;
  }
  &__progress {
    background: $color-grey-700;
    height: 1rem;
  }
}
