@import 'src/tokens';

.CampaignReport {
  flex-grow: 1;
  &__highest-preforming-container {
    display: flex;
    justify-content: space-between;
    flex-grow: 3;
    background-color: #fff;
    margin-right: 1rem;
    margin-top: 0.5rem;
    padding: 1rem;
  }
  &__creative-grid {
    display: flex;
    padding: 2rem;
  }
  &__devices-served {
    display: flex;
    flex-direction: column;
    background-color: white;
    flex-grow: 1;
    padding: 1.5rem;
    justify-content: space-between;
    margin-bottom: 12rem;
    margin-top: 0.5rem;
  }
  &__creative-grid-box {
    padding: 1rem;
    background-color: white;
    border: 1px solid transparent;
    &:hover {
      border: 1px solid black;
    }
  }
  &__big-image {
    font-size: 300px;
  }
}
