@import 'tokens.scss';

.Section {
  padding: 1rem;
  background: $color-grey-100;
  border-radius: $border-radius-s;
  &__body {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &__spacer {
    width: 100%;
    height: 1px;
    background: $color-grey-500;
    margin: 1rem 0;
  }
  &--dark {
    background: $color-grey-200;
  }
  &--no-background {
    background: transparent;
  }
  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    & > * {
      margin: 0 0.5rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
