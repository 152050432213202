@import 'tokens.scss';

.AreaSearchLens {
  display: flex;
  flex-grow: 1;
  &__sidebar-content {
    padding: 1rem;
  }
  &__map {
    flex-grow: 1;
    align-items: stretch;
    min-width: 200px;
    div[id^='mapid'] {
      height: 100%;
    }
  }
}
