@import 'src/tokens';
@import 'src/mixins';

.CampaignView {
  background-color: $color-grey-100;
  &__body {
    display: flex;
    flex-direction: row;
    flex: 1;
  }
  &__left-column {
    min-width: 35rem;
    padding: 1rem;
    background-color: $color-grey-200;
  }
  &__right-column {
    display: flex;
    flex-grow: 1;
    padding: 1rem;
  }
}
