:root {
  --color-data-warm-050: #fffeb3;
  --color-data-warm-100: #fffd45;
  --color-data-warm-200: #fedf21;
  --color-data-warm-300: #fbc200;
  --color-data-warm-400: #f6a400;
  --color-data-warm-500: #ef8500;
  --color-data-warm-600: #e66500;
  --color-data-warm-700: #da4100;
  --color-data-warm-800: #cc0000;
  --color-data-warm-900: #7a0000;

  --color-data-050: #b5f5ff;
  --color-data-100: #4de7ff;
  --color-data-200: #00cbeb;
  --color-data-300: #00bcff;
  --color-data-400: #43a6ff;
  --color-data-500: #918afb;
  --color-data-600: #ca63d7;
  --color-data-700: #ec2e9d;
  --color-data-800: #f20057;
  --color-data-900: #db0000;
}
