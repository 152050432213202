@import 'tokens.scss';

// override default leaflet styles
.leaflet-div-icon {
  border: none;
  background: transparent;
}

.leaflet-popup-content-wrapper {
  overflow: hidden;
  padding: 0;
}

.leaflet-popup-content {
  margin: 0;
}

.leaflet-popup {
  margin-bottom: 42px;
  margin-left: 4px;
}

.Map {
  height: 100%;
  &__fit-to-bounds-button {
    position: absolute;
    left: 10px;
    bottom: 10px;
    z-index: 1000;
  }
}
