@import 'tokens.scss';

.ProspectFinderSelection {
  &__header {
    background: $color-primary-100;
    padding: 1rem;
  }
  &__content {
    display: grid;
    gap: 1rem;
    padding: 1rem;
  }
  &__section {
    display: grid;
    gap: 1rem;
  }
}
