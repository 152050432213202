.RealEstateInfoForm {
  display: flex;
  padding: 1rem;
  &__description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    padding: 1rem;
  }
  &__icon {
    margin: 0 auto 0.5rem auto;
    color: #2b1f09;
  }
  &__progress {
    margin: 1rem 0;
  }
  &__inputs {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    border-left: 2px solid #2b1f09;
    padding: 1rem;
  }
  &__submit {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
  }
}
