@import 'src/tokens';
@import 'src/mixins';

.Campaigns {
  &__select-action {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 1rem;

    @include focus-ring;
  }
}
