.MyListingsLens {
  display: flex;
  flex-grow: 1;
  &__sidebar-content {
    padding: 1rem;
  }
  &__button-wrapper {
    display: flex;
    justify-content: center;
    padding: 1rem;
  }
  &__map {
    flex-grow: 1;
    align-items: stretch;
    min-width: 200px;
    div[id^='mapid'] {
      height: 100%;
    }
  }
}
