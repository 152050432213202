@import 'tokens.scss';
@import 'mixins.scss';

.MapSearch {
  &__button {
    width: 100%;
    border: none;
    border-radius: $border-radius-s;
    text-align: start;
    font-size: $text-size-l;
    padding: 0.5rem 1rem;
    display: grid;
    grid-template-columns: min-content auto;
    align-items: center;
    gap: 1rem;
    cursor: pointer;

    @include focus-ring;
    background-color: $color-grey-100;
    color: $text-on-grey-100;
    &:hover {
      background-color: $color-grey-200;
      color: $text-on-grey-200;
    }
  }
  &__model-content {
    max-height: calc(100vh - 12rem);
    width: 60rem;
    max-width: calc(100vw - 2rem);
    display: flex;
    flex-direction: column;
    padding: 1rem;
    padding-bottom: 0.5rem;
  }
  &__search {
    flex-grow: 0;
    flex-shrink: 0;
    border: $border-width-default solid $color-grey-100;
    margin-bottom: $pixels-4;
    border-radius: $border-radius-m;
    overflow: hidden;
    &:focus-within {
      box-shadow: $focus-ring;
    }
  }
  &__icon {
    margin: 0 0.75rem;
    font-size: $text-size-l;
    color: $text-on-white--subdued;
  }
  &__input {
    border: none;
    outline: none;
    height: 2.5rem;
    font-family: 'Inter', sans-serif;
    font-size: $text-size-xl;
    font-weight: $text-weight-light;
    &::placeholder {
      color: $text-on-white--subdued;
    }
  }
  &__suggestions {
    flex-grow: 0;
    list-style: none;
    margin: 1rem 0;
    padding: 0;
  }
  &__suggestion-button {
    display: block;
    width: 100%;
    text-align: start;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: $border-radius-m;
    border: none;
    background-color: transparent;
    font-size: $text-size-m;
    &:hover {
      background-color: $color-grey-050;
      color: $text-on-grey-050;
    }
  }
}
