.ManageAvatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
  &__avatar {
    margin: 1rem;
  }
  button {
    margin-bottom: 0.5rem;
  }
}
