@import 'tokens.scss';

.CampaignDashboard {
  &__body {
    display: flex;
  }
  &__left-column {
    width: 60%;
    margin: 1rem;
  }
  &__right-column {
    width: 40%;
    margin: 1rem;
  }
  &__balance-section {
    display: flex;
    justify-content: space-between;
  }
  &__campaign-type {
    display: flex;
    justify-content: space-between;
    &-text {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin: 1rem;
    }
  }
  &__in-progress-campaign-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    padding-right: 1rem;
    margin: 1rem;
  }
  &__in-progress-campaign-titles {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
  }
  &__in-progress-campaign-circle {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
  }
  &__campaign-performance {
    height: 100%;
  }
  &__faded-box {
    margin: 1rem;
    height: 175px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: darkgray;
  }
}
