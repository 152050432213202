
@import 'tokens';

.Tags {
  display: flex;
  flex-direction: row;
  &__tag {
    text-align: center;
    background: $color-grey-200;
    margin: 0.25rem;
    padding: 0.25rem 0.5rem;
    color: $text-on-grey-200;
  }
  &__overflow {
    border: $pixels-1 solid $color-grey-200;
    color: $text-on-white--subdued;
    background: $color-white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
  }
  &__tag,
  &__overflow {
    border-radius: $border-radius-xl;
    font-size: $text-size-xs;
  }
  &__tooltip {
    position: fixed;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border: $pixels-1 solid $color-grey-200;
    background: $color-white;
    padding: 0.25rem;
    max-height: 10rem;
    overflow-y: auto;
    border-radius: $border-radius-m;
    box-shadow: $box-shadow-300;
  }
}
