@import 'tokens';

.ListingMap {
  height: 20rem;
  flex-grow: 1;
  box-shadow: $box-shadow-200;
  margin: 0.5rem;
  &__map-loading {
    height: 20rem;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-grey-050;
  }
  // disabling tooltip/popup for map marker
  .leaflet-marker-icon {
    pointer-events: none;
  }
}
