@import 'tokens.scss';

.FindYourProspectsLens {
  display: flex;
  flex-grow: 1;
  &__sidebar-content {
    padding: 1rem;
  }
  &__filter-selection {
    position: fixed;
    width: max-content;
    background-color: $color-white;
    border: $border-width-thin solid $color-grey-200;
    border-radius: $border-radius-m;
    box-shadow: $box-shadow-400-dark;
    overflow: hidden;
    margin-top: 0.25rem;
    padding: 0.25rem;
  }
  &__map {
    flex-grow: 1;
    align-items: stretch;
    min-width: 200px;
    div[id^='mapid'] {
      height: 100%;
    }
  }
}
