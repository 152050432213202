@import 'tokens.scss';

.CampaignCreatives {
  height: 100%;
  &__choose-creatives {
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }
  &__types {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }
  &__upload {
    grid-column: 2 / 4;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
