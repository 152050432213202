@import 'tokens.scss';

.ListingsList {
  &__controls {
    display: flex;
    margin-bottom: 0.5rem;
    align-items: center;
  }
  &__search {
    margin-right: 1rem;
    margin-left: auto;
    flex-basis: 33%;
  }
  &__listings {
    display: grid;
    gap: $pixels-4;
  }
}
