@import 'tokens.scss';

.DashboardPage {
  padding: 1rem;
  // arbitrary max-width, would be better wrapped with a page layout component in the future
  max-width: 80rem;
  margin: 0 auto;
  // see above comment ^
  height: 100%;
  display: flex;
  flex-direction: column;
  &__header {
    display: flex;
    justify-content: space-between;
  }
}

.LatestListings {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 1rem;
  &__map {
    background: $color-grey-100;
    border-radius: $border-radius-s;
    box-shadow: $box-shadow-100;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.SphereOfInfluence {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: $color-grey-050;
  flex-grow: 1;
}
