.OnboardingModal {
  &__welcome {
    padding: 2rem;
    display: flex;
    flex-direction: column;
  }
  &__welcomeHeader {
    margin-bottom: 2rem;
  }
}
