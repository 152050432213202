@import 'tokens.scss';
@import 'mixins.scss';

.SummaryEditButton {
  border-radius: $border-radius-s;
  border: $border-width-default solid transparent;
  background: transparent;
  font-size: $text-size-m;
  color: $text-on-grey-100--tertiary;
  outline: none;
  cursor: pointer;

  @include focus-ring;
  &:hover {
    color: $text-on-grey-300--tertiary;
    border-color: $text-on-grey-100--tertiary;
  }
}
