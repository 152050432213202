@import 'tokens.scss';

.DateRangeSelector {
  background-color: $color-white;
  padding: 1rem;
  margin-bottom: 1rem;
  &__body {
    display: flex;
  }
  &__date-selection {
    flex-basis: max-content;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__date-display {
    flex-grow: 1;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
