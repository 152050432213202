@import 'tokens.scss';

.ProspectsFilter {
  padding: 0.5rem 0;
  &:first-of-type {
    padding-top: 0;
  }
  &:last-of-type {
    padding-bottom: 0;
  }
}
