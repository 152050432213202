@import 'src/tokens';
@import 'src/mixins';

.CompactSavedAudiences {
  &__audience-container {
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border: 1px solid transparent;
    &:hover {
      border: 1px solid black;
    }
  }
  &__image {
    flex-grow: 1;
    padding-right: 1rem;
  }
  &__body {
    flex-grow: 4;
    display: flex;
    flex-direction: column;
  }
  &__description {
    flex-grow: 4;
    padding-left: 1rem;
  }
  &__prospects {
    flex-grow: 1;
    display: flex;
    padding-left: 1rem;
  }
  &__prospects-text {
    display: flex;
    flex-direction: column;
  }
}
