.MapMarker {
  height: 20px;
  width: 20px;
  position: relative;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.75));
  &__svg-icon {
    position: absolute;
    left: 50%;
    bottom: 50%;
    transform: translateX(-50%);
    display: block;
    height: 2rem;
  }
  &__svg-marker-path {
    stroke: white;
    stroke-width: 50;
    stroke-width: 40;
    stroke-miterlimit: 10;
  }
  &__svg-circle {
    fill: white;
  }
}
