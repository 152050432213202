@import 'src/tokens';

.MyProfilePage {
  height: 100%;
  display: flex;
  align-items: stretch;
  &__nav {
    width: 20%;
    max-width: 20rem;
    border-right: $pixels-1 solid $color-grey-300;
    background-color: $color-grey-050;
  }
  &__main {
    flex-grow: 1;
    overflow-y: auto;
    padding: 2rem;
    background-color: $color-white;
  }
  &__header {
    padding: 0.5rem 1rem;
  }
}
