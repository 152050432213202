@import 'tokens';

.ListingView {
  background-color: $color-grey-100;
  &__body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    min-height: calc(100% - 5.2rem);
  }
  &__left-column {
    min-width: 35rem;
    padding: 1rem;
    background-color: $color-grey-200;

    @media (max-width: 1081px) {
      flex-grow: 1;
    }
  }
  &__right-column {
    display: flex;
    flex-grow: 1;
    padding: 1rem;
  }
  &__status {
    display: flex;
    align-items: center;
    background: $color-tertiary-500;
    border-radius: $border-radius-m;
    color: $text-on-tertiary-500;
    padding: 0.2rem 0.4rem;
    font-size: $text-size-s;
  }
  &__error {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: hidden;
  }
  &__go-back {
    background: $color-white;
    padding: 1rem 0 0 1rem;
  }
  &__go-back-btn {
    width: max-content;
  }
}
