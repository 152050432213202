@import 'src/tokens';

.PersonalInfoSection {
  &__avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
  }
}
