@import 'tokens.scss';

.LensSidebarHeader {
  &__back-button {
    display: inline-block;
    border-radius: $border-radius-m;
    margin: 0.25rem 0.5rem;
    padding: 0.125rem 0.5rem;
    &:link,
    &:visited {
      color: $text-on-white--subdued;
      text-decoration: none;
    }
    &:hover {
      color: $text-on-grey-100;
      background-color: $color-grey-100;
    }
  }
  &__divider {
    background-color: $color-grey-200;
    height: $pixels-2;
    margin-bottom: 0.5rem;
  }
  &__heading {
    margin: 0 1rem;
    color: $text-on-white;
    font-size: $text-size-xxxl;
    font-weight: $text-weight-bold;
  }
  &__sub-heading {
    margin: 0 1rem;
  }
}
