@import 'src/tokens';

.ErrorBoundary {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__graphic {
    margin-bottom: 2rem;
  }
}
