@import 'tokens';
@import 'mixins';

.Notes {
  margin: $pixels-3;
  &__textarea {
    width: calc(100% - 0.5rem);
    border: none;
    padding: 1rem;
    resize: none;
    font-family: inherit;
    font-size: $text-size-s;

    @include focus-ring;
  }
  &__edit-btn {
    min-width: 4.5rem;
  }
}
