@import 'tokens.scss';

.UploadMailerModal {
  padding: 1rem;
  display: grid;
  gap: 1rem;
  &__hl {
    height: $pixels-2;
    width: 100%;
    background-color: $color-grey-300;
  }
  &__creative-placeholder {
    border: $border-width-default solid $color-grey-300;
    background-color: $color-grey-100;
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
  }
  &__cancel-button {
    margin-right: 1rem;
  }
}
