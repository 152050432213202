@import 'tokens';

.CreditModal {
  width: max-content;
  max-width: calc(100vw - 1rem);
  &__header {
    display: flex;
    flex-direction: column;
  }
  &__content {
    padding: 0 2rem 2rem 2rem;
  }
}
