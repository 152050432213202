.CreativeThumb {
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &__image {
    max-height: 100%;
    max-width: 100%;
  }
}
