@import 'src/tokens';

.SubscriptionCard {
  display: flex;
  flex-direction: column;
  &__header {
    margin: 0;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    letter-spacing: $pixels-2;
    color: $color-grey-400;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    background-color: $color-grey-050;
    padding: 1rem;
    box-shadow: $box-shadow-200;
    flex-grow: 1;
    &--locked {
      color: $color-grey-100;
      cursor: not-allowed;
    }
  }
  &__title {
    text-align: center;
  }
  &__button {
    pointer-events: none;
  }
}
